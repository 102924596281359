import React, { useState } from "react";
import { Box } from "@material-ui/core";
import {
  MultiSelect,
  CTextField,
  HFlex,
  ProgressButton,
  Text,
  ErrorBox,
  DialogSuccessNotice,
  useErrorContext,
  CDialog,
} from "shared";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Send } from "assets";
import useInvitePartner, { Vars } from "../../queries/useInvitePartner";
import { ReferralSourceForm, ReferralSourceBody, useReferralSources, useAddReferralSource } from "shared/ValueTable";
import { delay } from "utils/misc";
import { FORM_NOTIFICATION_DELAY } from "theme";
import { useMountState } from "utils/hooks";
import { css } from "@emotion/css";

interface Props {
  onClose: () => void;
}
interface SourceFormDialog {
  isOpen: boolean;
  formDefaults?: ReferralSourceBody;
}

const InvitePartnerForm: React.VFC<Props> = ({ onClose }) => {
  const form = useForm<Vars>();
  const { t } = useTranslation();
  const setError = useErrorContext();
  const getMountState = useMountState();
  const referralSources = useReferralSources();
  const { addRecord: addReferralSource } = useAddReferralSource();
  const [sourceFormDialog, setSourceFormDialog] = useState<SourceFormDialog>({ isOpen: false });

  const { mutateAsync, isLoading, isSuccess } = useInvitePartner();

  const submit = form.handleSubmit(async fields => {
    try {
      await mutateAsync(fields);
      if (!getMountState()) return;
      await delay(FORM_NOTIFICATION_DELAY);
      if (!getMountState()) return;
      onClose();
    } catch (err) {
      setError(t`errors.could_not_send_invite`, err);
    }
  });

  const closeReferralForm = () => {
    setSourceFormDialog({ isOpen: false });
  };

  const addReferral = async (referralSource: ReferralSourceBody) => {
    try {
      await addReferralSource(referralSource);
      const allSources = form.getValues("sources") as ReferralSourceBody[];
      form.setValue("sources", [...allSources, referralSource.value]);
      closeReferralForm();
    } catch (err) {
      setSourceFormDialog({ isOpen: true, formDefaults: referralSource });
      setError(t`errors.could_not_create_referral_source`, err);
    }
  };

  if (isSuccess)
    return (
      <DialogSuccessNotice
        heading={t("patients.invite_sent")}
        body={`${t("form.invitation_successfully_sent_to")} ${form.getValues("email")}`}
        onClose={onClose}
      />
    );

  return (
    <Box mx={3}>
      <Text heading bold mb={3} id="invite-partner">
        {t("form.invite_partner")}
      </Text>
      <FormProvider {...form}>
        <form onSubmit={submit}>
          <CTextField
            required
            name="email"
            autoFocus
            label={t("form.email")}
            type="email"
            margin="normal"
            size="small"
            fullWidth
          />
          <HFlex gap={2}>
            <CTextField
              fullWidth
              name="first_name"
              label={t("form.first_name")}
              margin="normal"
              size="small"
              required
            />
            <CTextField fullWidth name="last_name" label={t("form.last_name")} margin="normal" size="small" required />
          </HFlex>
          <div className={styles.spanRow}>
            <MultiSelect
              margin="normal"
              multiple
              size="small"
              name="sources"
              getOptionLabel={({ value }) => value ?? "NAME MISSING"}
              loading={referralSources.isLoading}
              noOptionsText={referralSources.isError && <ErrorBox error={referralSources.error} />}
              label={t`form.which_clinic`}
              options={referralSources.data ?? []}
              disableCloseOnSelect
            />
            <Text paragraph tiny link role="button" mt={-0.5} onClick={() => setSourceFormDialog({ isOpen: true })}>
              {t`form.new_clinic`}
            </Text>
          </div>
          <HFlex justifyContent="center" my={6}>
            <ProgressButton loading={isLoading} endIcon={<Send width={20} />}>
              {t("buttons.send_invitation")}
            </ProgressButton>
          </HFlex>
        </form>
      </FormProvider>
      <CDialog
        maxWidth="xs"
        fullWidth
        open={sourceFormDialog.isOpen}
        onClose={closeReferralForm}
        aria-labelledby="add-record"
      >
        <ReferralSourceForm onCancel={closeReferralForm} onAdd={addReferral} defaults={sourceFormDialog.formDefaults} />
      </CDialog>
    </Box>
  );
};

export default InvitePartnerForm;

const styles = {
  label: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  spanRow: css`
    grid-column: 1 / span 2;
  `,
};
