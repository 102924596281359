import React, { useState } from "react";
import { BoxButton, CDialog, Icon } from "shared";
import { InviteTherapistForm } from "./InviteTherapistForm";
import { Send } from "assets";
import { useTranslation } from "react-i18next";

export const InviteTherapist: React.VFC = () => {
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();

  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);

  return (
    <>
      <BoxButton startIcon={<Icon of={Send} />} onClick={openDialog}>
        {t("form.invite_therapist")}
      </BoxButton>

      <CDialog maxWidth="xs" fullWidth open={isOpen} onClose={closeDialog} aria-labelledby="invite-doctor">
        <InviteTherapistForm onClose={closeDialog} />
      </CDialog>
    </>
  );
};
