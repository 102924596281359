import React from "react";
import { Box, Container, TableCell } from "@material-ui/core";
import { SearchTherapistForm } from "./components";
import { CTable, ErrorBox, Text, VFlex, Icon, Loading } from "shared";
import useListTherapists, { Category } from "./queries/useListTherapists";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "utils/hooks";
import { Country, countries } from "utils/market";
import { Area, WithChildren } from "types";
import { RowLink } from "shared/CTable/CTable";
import { join, stripDeletedTagFromEmail } from "utils/misc";
import { Circle } from "assets";
import { definitions } from "api";

type PT = definitions["search_result_therapist"];

const Cell: React.VFC<WithChildren> = ({ children }) => (
  <TableCell>
    <Text tiny>{children}</Text>
  </TableCell>
);

const TherapistSearch: React.VFC = () => {
  const { t } = useTranslation();

  const searchQuery = useSearchParams(
    {
      country: countries[0].value,
      category: Category.id,
      search: "",
    },
    {
      country: Object.values(Country),
      category: Object.values(Category),
    }
  );

  const { data, error, isLoading, isLoadingError: isError, isSuccess, isIdle } = useListTherapists(searchQuery);

  const tableHeader = {
    id: t("patients.id"),
    first_name: t("form.first_name"),
    last_name: t("form.last_name"),
    email: t("form.email"),
    health_care_provider_name: t("form.health_care_provider"),
    available_for_patients: t("form.accepting_patients"),
  };

  const tableCellFactory = (item: PT) => ({
    id: (
      <Cell>
        <RowLink to={{ pathname: join(Area.Therapists, item.id), state: window.location.href.match(/\?.+$/)?.[0] }} />
        <Icon of={Circle} color={item.deleted_at ? "error" : "success"} size={0.8} />
        <Text tiny ml={0.5}>
          {item.id}
        </Text>
      </Cell>
    ),
    first_name: <Cell>{item.first_name}</Cell>,
    last_name: <Cell>{item.last_name}</Cell>,
    email: <Cell>{item.email && stripDeletedTagFromEmail(item.email)}</Cell>,
    health_care_provider_name: <Cell>{item.health_care_provider_name}</Cell>,
    available_for_patients: <Cell>{item.available_for_patients ? t("buttons.yes") : t("buttons.no")}</Cell>,
  });

  const noMatchEm = (
    <Text bold secondary paragraph mb={0} center>
      {t("dashboard.tables.no_therapists_found")}
    </Text>
  );

  const tableEm = (
    <Container>
      <CTable header={tableHeader} items={(data as any)?.items} cellFactory={tableCellFactory} />
    </Container>
  );

  const resultEm = (
    <Box bgcolor="background.paper" py={2} overflow="auto" data-testid="search-results">
      {isError && <ErrorBox error={error!} verbose centered dark />}
      {isLoading && <Loading />}
      {isSuccess && (!!(data as any)?.items.length ? tableEm : noMatchEm)}
    </Box>
  );

  return (
    <VFlex>
      <Container maxWidth="md">
        <Box mb={3} style={{ marginTop: "108px" }}>
          <SearchTherapistForm {...searchQuery} />
        </Box>
      </Container>
      {!isIdle && resultEm}
    </VFlex>
  );
};

export default TherapistSearch;
