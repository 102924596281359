import api from "api";
import { useMutation } from "react-query";
import { Vars } from "../components/InviteChenMedPatient/InviteChenMedPatientForm";

const OP = "POST /invitations/provider_group_patient";

const useInviteChenMedPatient = () =>
  useMutation<any, Error, Vars>(
    ({
      patient_first_name,
      patient_last_name,
      language_code,
      alt_language,
      invite_clinic,
      invite_timezone,
      invite_source,
      invited_joint,
      phone_country_code,
      phone_number,
      email,
      physician_first_name,
      physician_last_name,
      file,
      source,
    }) => {
      const data = new FormData();
      data.append("patient_first_name", patient_first_name as string);
      data.append("patient_last_name", patient_last_name as string);
      data.append("language_code", language_code as string);
      data.append("alt_language", alt_language as string);
      data.append("invite_clinic", invite_clinic as string);
      if (invite_timezone) {
        data.append("invite_timezone", invite_timezone);
      }
      data.append("invite_source", invite_source as string);
      if (invited_joint) {
        data.append("invited_joint", invited_joint);
      }
      data.append("phone_country_code", phone_country_code as string);
      if (phone_number) {
        data.append("phone_number", phone_number);
      }
      if (email) {
        data.append("email", email);
      }
      data.append("physician_first_name", physician_first_name as string);
      data.append("physician_last_name", physician_last_name as string);
      if (file) {
        const [doc] = file;
        data.append("file", doc, doc.name);
      }
      if (source) {
        data.append("source", source);
      }

      // @ts-ignore
      return api.parse(OP, { data });
    }
  );

export default useInviteChenMedPatient;
