import { Country, TimeZone } from "utils/market";

export const timezonesUS = [
  { value: TimeZone.Pacific, label: "Pacific Time" },
  { value: TimeZone.Mountain, label: "Mountain Time" },
  { value: TimeZone.Central, label: "Central Time" },
  { value: TimeZone.Eastern, label: "Eastern Time" },
];

export const timezonesEU = [
  { value: TimeZone.London, label: "London Time" },
  { value: TimeZone.Stockholm, label: "Stockholm Time" },
  { value: TimeZone.Berlin, label: "Berlin Time" },
  { value: TimeZone.Paris, label: "Paris Time" },
];

export const countriesEU = [
  { value: Country.UK, label: "United Kingdom" },
  { value: Country.DE, label: "Germany" },
  { value: Country.FR, label: "France" },
  { value: Country.SE, label: "Sweden" },
];
