import React from "react";
import { Container } from "@material-ui/core";
import InvitePartner from "./components/invitePartner";
import { isUsMarket } from "utils/market";
import ManageTreatments from "./components/ManageTreatments";
import FRInvoices from "./components/FRInvoices";
import { BoxButton } from "shared";
import { useTranslation } from "react-i18next";
import { Area } from "types";
import { join } from "utils/misc";
import { Link } from "react-router-dom";
import { ViewComfy, Edit } from "@material-ui/icons";
import CareEventConfigurations from "./components/CareEventConfigurations";
import { InvitePhysician } from "./components/InvitePhysician";
import { InviteChenMedPatient } from "./components/InviteChenMedPatient";
import { InviteTherapist } from "./components/InviteTherapist";

const Other: React.VFC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Container maxWidth="sm">
        {isUsMarket && (
          <Link to={Area.UnclaimedMedicalReferrals}>
            <BoxButton startIcon={<ViewComfy />}>{t("views.unclaimed_medical_referrals")}</BoxButton>
          </Link>
        )}
        {isUsMarket && <InviteChenMedPatient />}
        {isUsMarket && <InvitePartner />}
        {isUsMarket && <InvitePhysician />}
        <InviteTherapist />
        <ManageTreatments />
        <FRInvoices />
        <Link to={join(Area.Registry, "referral-sources")}>
          <BoxButton startIcon={<ViewComfy />}>{t("views.registry")}</BoxButton>
        </Link>
        <CareEventConfigurations />
        <Link to={Area.Payers}>
          <BoxButton startIcon={<ViewComfy />}>{t("views.payers")}</BoxButton>
        </Link>
        <Link to={Area.LessonEditor}>
          <BoxButton startIcon={<Edit />}>{t("views.lessonEditor")}</BoxButton>
        </Link>
      </Container>
    </Container>
  );
};

export default Other;
