import React from "react";
import { Box } from "@material-ui/core";
import {
  CTextField,
  CSelect,
  HFlex,
  ProgressButton,
  Text,
  DialogSuccessNotice,
  useErrorContext,
  MultiSelect,
  ErrorBox,
} from "shared";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Send } from "assets";
import { delay } from "utils/misc";
import { Country } from "utils/market";
import { FORM_NOTIFICATION_DELAY } from "theme";
import { useMountState } from "utils/hooks";
import useInvitePhysician, { Vars } from "routes/other/queries/useInvitePhysician";
import { timezonesUS } from "../helpers";
import { useStates } from "shared/ValueTable";

interface Props {
  onClose: () => void;
}

export const InvitePhysicianForm: React.VFC<Props> = ({ onClose }) => {
  const form = useForm<Vars>();
  const { t } = useTranslation();
  const setError = useErrorContext();
  const getMountState = useMountState();
  const licenses = useStates();

  const { mutateAsync, isLoading, isError, data } = useInvitePhysician();

  const submit = form.handleSubmit(async fields => {
    try {
      await mutateAsync(fields);
      if (!getMountState()) return;
      await delay(FORM_NOTIFICATION_DELAY);
      if (!getMountState()) return;
      onClose();
    } catch (err) {
      setError(t`errors.could_not_send_invite`, err);
    }
  });

  if (!isError && data)
    return (
      <DialogSuccessNotice
        heading={t("patients.invite_sent")}
        // @ts-ignore
        body={`${t("form.invitation_successfully_sent_to")} ${data.email}`}
        onClose={onClose}
      />
    );

  return (
    <Box mx={3}>
      <Text heading bold mb={3} id="invite-doctor">
        {t("form.invite_physician")}
      </Text>
      <FormProvider {...form}>
        <form onSubmit={submit}>
          <CTextField
            required
            name="email"
            autoFocus
            label={t("form.email")}
            type="email"
            margin="normal"
            size="small"
            fullWidth
          />

          <HFlex gap={2}>
            <CTextField
              fullWidth
              name="first_name"
              label={t("form.first_name")}
              margin="normal"
              size="small"
              required
            />
            <CTextField fullWidth name="last_name" label={t("form.last_name")} margin="normal" size="small" required />
          </HFlex>

          <CTextField required name="npi" label={t("form.npi")} margin="normal" size="small" fullWidth />

          <CSelect
            name="timezone"
            label={t("form.home_timezone")}
            items={timezonesUS}
            margin="normal"
            size="small"
            required
            fullWidth
          >
            {({ label }) => <Text>{label}</Text>}
          </CSelect>

          <MultiSelect
            getOptionLabel={({ value, code }) => `${value} (${code})`}
            getOptionValue={opt => opt.code}
            loading={licenses.isLoading}
            noOptionsText={licenses.error ? <ErrorBox error={licenses.error} /> : ""}
            name="us_licenses"
            options={licenses.data ?? []}
            label={t`form.state_licenses`}
            margin="normal"
            disableCloseOnSelect
          />

          <CTextField name="country" required type="hidden" noClear variant="standard" defaultValue={Country.US} />

          <HFlex justifyContent="center" my={6}>
            <ProgressButton loading={isLoading} endIcon={<Send width={20} />}>
              {t("buttons.send_invitation")}
            </ProgressButton>
          </HFlex>
        </form>
      </FormProvider>
    </Box>
  );
};
