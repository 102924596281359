import api from "api";
import { BodyParams } from "api/api";
import { useMutation } from "react-query";

const OP = "POST /invitations/physician";

// @ts-ignore
export type Vars = BodyParams<typeof OP>;

const useInvitePhysician = () =>
  useMutation((data: Vars) => {
    // @ts-ignore
    return api.parse(OP, { data });
  });

export default useInvitePhysician;
