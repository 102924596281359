import React, { useState } from "react";
import { BoxButton, CDialog, Icon } from "shared";
import { InviteChenMedPatientForm } from "./InviteChenMedPatientForm";
import { Send } from "assets";
import { useTranslation } from "react-i18next";

export const InviteChenMedPatient: React.VFC = () => {
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();

  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);

  return (
    <>
      <BoxButton startIcon={<Icon of={Send} />} onClick={openDialog}>
        {t("form.invite_chenmed_patient")}
      </BoxButton>

      <CDialog maxWidth="xs" fullWidth open={isOpen} onClose={closeDialog} aria-labelledby="invite-chenmed-patient">
        <InviteChenMedPatientForm onClose={closeDialog} />
      </CDialog>
    </>
  );
};
