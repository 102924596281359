import React from "react";
import { Box } from "@material-ui/core";
import {
  CAutocomplete,
  CTextField,
  CSelect,
  HFlex,
  ProgressButton,
  Text,
  DialogSuccessNotice,
  useErrorContext,
  FileUpload,
  ErrorBox,
} from "shared";
import { useReferralSources } from "shared/ValueTable";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Send } from "assets";
import { delay } from "utils/misc";
import { FORM_NOTIFICATION_DELAY } from "theme";
import { useMountState } from "utils/hooks";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import useInviteChenMedPatient from "routes/other/queries/useInviteChenMedPatient";

const schema = z
  .object({
    patient_first_name: z.string().nonempty("errors.required"),
    patient_last_name: z.string().nonempty("errors.required"),
    language_code: z.string().nonempty("errors.required"),
    alt_language: z.string().nonempty("errors.required"),
    invite_clinic: z.string().nonempty("errors.required"),
    invite_timezone: z.string().nullable(),
    invite_source: z.string().nonempty("errors.required"),
    invited_joint: z.string().nullable(),
    phone_country_code: z.string().nonempty("errors.required"),
    phone_number: z.string().nullable(),
    email: z.string().nullable(),
    physician_first_name: z.string().nonempty("errors.required"),
    physician_last_name: z.string().nonempty("errors.required"),
    file: z.any().nullable(),
    source: z.string().nullable(),
  })
  .partial()
  .superRefine((data, ctx) => {
    if (!data.phone_number && !data.email) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["email"],
        message: "Either email or phone number should be filled in",
      });
    }
  });

export type Vars = z.infer<typeof schema>;

const BODY_PARTS = [
  { value: "hip", label: "hip" },
  { value: "knee", label: "knee" },
  { value: "lower back", label: "lower Back" },
  { value: "shoulder", label: "shoulder" },
  { value: "hand", label: "hand" },
  { value: "neck", label: "neck" },
  { value: "elbow", label: "elbow" },
  { value: "foot", label: "foot" },
  { value: "wrist", label: "wrist" },
  { value: "ankle", label: "ankle" },
  { value: "fall prevention", label: "fall prevention" },
  { value: "osteoporosis", label: "osteoporosis" },
];

const CLINICS = [
  { value: "Chen Senior Medical Center", label: "Chen Senior Medical Center" },
  { value: "Dedicated Senior Medical Center", label: "Dedicated Senior Medical Center" },
  { value: "Intune Health", label: "Intune Health" },
  { value: "JenCare Senior Medical Center", label: "JenCare Senior Medical Center" },
];

const TIMEZONES = [
  { value: "pacific_time", label: "Pacific Time" },
  { value: "mountain_time", label: "Mountain Time" },
  { value: "central_time", label: "Central Time" },
  { value: "eastern_time", label: "Eastern Time" },
];

interface Props {
  onClose: () => void;
}

export const InviteChenMedPatientForm: React.VFC<Props> = ({ onClose }) => {
  const form = useForm<Vars>({
    defaultValues: {
      email: null,
      invite_timezone: null,
      invited_joint: null,
    },
    mode: "onChange",
    resolver: zodResolver(schema),
  });
  const { t } = useTranslation();
  const setError = useErrorContext();
  const getMountState = useMountState();

  const referralSources = useReferralSources();
  const { mutateAsync, isLoading, isError, data } = useInviteChenMedPatient();

  const {
    handleSubmit,
    formState: { errors },
  } = form;

  console.log(errors);

  const submit = handleSubmit(async fields => {
    try {
      // @ts-ignore
      await mutateAsync(fields);
      if (!getMountState()) return;
      await delay(FORM_NOTIFICATION_DELAY);
      if (!getMountState()) return;
      onClose();
    } catch (err) {
      setError(t`errors.could_not_send_invite`, err);
    }
  });

  if (!isError && data)
    return (
      <DialogSuccessNotice
        heading={t("patients.invite_sent")}
        // @ts-ignore
        body={`${t("form.invitation_successfully_sent_to")} ${data.email}`}
        onClose={onClose}
      />
    );

  return (
    <Box mx={3}>
      <Text heading bold id="invite-doctor">
        {t("form.invite_chenmed_patient")}
      </Text>
      <FormProvider {...form}>
        <form onSubmit={submit}>
          <HFlex gap={2}>
            <CTextField
              fullWidth
              name="patient_first_name"
              label={t("form.first_name")}
              margin="normal"
              size="small"
              error={!!errors.patient_first_name}
              required
            />
            <CTextField
              fullWidth
              name="patient_last_name"
              label={t("form.last_name")}
              margin="normal"
              size="small"
              required
            />
          </HFlex>

          <CTextField name="language_code" type="hidden" variant="standard" noClear defaultValue={"en"} required />

          <CSelect
            name="alt_language"
            label={t("form.language")}
            items={[
              { value: "en", label: "English" },
              { value: "es", label: "Spanish" },
            ]}
            margin="normal"
            size="small"
            defaultValue={"en"}
            fullWidth
          >
            {({ label }) => <Text>{label}</Text>}
          </CSelect>

          <HFlex gap={2}>
            <CTextField
              fullWidth
              name="physician_first_name"
              label={t("form.physician_first_name")}
              margin="normal"
              size="small"
              required
            />
            <CTextField
              fullWidth
              name="physician_last_name"
              label={t("form.physician_last_name")}
              margin="normal"
              size="small"
              required
            />
          </HFlex>

          <CSelect
            name="invite_timezone"
            label={t("form.home_timezone")}
            items={TIMEZONES}
            margin="normal"
            size="small"
            fullWidth
          >
            {({ label }) => <Text>{label}</Text>}
          </CSelect>

          <HFlex gap={2}>
            <CSelect
              name="invite_clinic"
              label={t("form.clinic")}
              items={CLINICS}
              margin="normal"
              size="small"
              required
              fullWidth
            >
              {({ label }) => <Text>{label}</Text>}
            </CSelect>
            <CSelect
              name="invited_joint"
              label={t("form.invited_joint")}
              items={BODY_PARTS}
              margin="normal"
              size="small"
              fullWidth
            >
              {({ label }) => <Text>{label}</Text>}
            </CSelect>
          </HFlex>

          {/* TODO: Fix adding +1 after cleaing the phone_number */}
          <Controller
            name="phone_number"
            control={form.control}
            render={() => (
              <PhoneInput
                disableCountryGuess
                disableDropdown
                countryCodeEditable={true}
                country={"us"}
                onlyCountries={["us"]}
                inputProps={{
                  name: "phone_number",
                }}
                onChange={inputValue => {
                  form.setValue("phone_number", inputValue);
                }}
                placeholder={t("form.phone_number")}
                containerStyle={{ width: "100%", margin: "16px 0 8px" }}
                inputStyle={{ width: "100%" }}
              />
            )}
          />

          <CTextField name="phone_country_code" type="hidden" variant="standard" noClear defaultValue={"US"} required />

          <CTextField name="email" label={t("form.email")} type="email" margin="normal" size="small" fullWidth />

          {errors.email && <Text color="error">{errors.email.message}</Text>}

          <CTextField
            name="invite_source"
            type="hidden"
            variant="standard"
            noClear
            defaultValue={"chenmed_invite"}
            required
          />

          <FileUpload
            size="small"
            name="file"
            label={t("patients.document")}
            fullWidth
            style={{ margin: "16px 0 8px" }}
          />

          <CAutocomplete
            size="small"
            name="source"
            getOptionLabel={({ value }) => value ?? "NAME MISSING"}
            loading={referralSources.isLoading}
            noOptionsText={referralSources.isError && <ErrorBox error={referralSources.error} />}
            label={t`form.source`}
            options={referralSources.data ?? []}
          />

          <HFlex justifyContent="center" my={6}>
            <ProgressButton loading={isLoading} endIcon={<Send width={20} />}>
              {t("buttons.send_invitation")}
            </ProgressButton>
          </HFlex>
        </form>
      </FormProvider>
    </Box>
  );
};
